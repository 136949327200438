import {
    useState,
    useCallback,
} from 'react';

import { InteractionType, PopupRequest } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";


/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useFetchWithMsal = (msalRequest) => {
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const { result, error: msalError } = useMsalAuthentication(InteractionType.Popup, {
        ...msalRequest,
        account: instance.getActiveAccount(),
        redirectUri: '/redirect.html'
    });

    /**
     * Execute a fetch request with the given options
     * @param {string} method: GET, POST, PUT, DELETE
     * @param {String} endpoint: The endpoint to call
     * @param {Object} data: The data to send to the endpoint, if any
     * @returns JSON response
     */
    const execute = async (method, endpoint, data = null, isBlob = false) => {
       
        if (msalError) {
            console.log('MSAL ERROR');
            console.log(msalError);
            setError(msalError);
            location.reload();
            return;
        }
        let  res = instance.acquireTokenSilent({
            ...msalRequest,
            account: instance.getActiveAccount()
        });
 
        let tempToken = await res;
 
        if (result) {
            try {
                if (tempToken?.accessToken !== null) {
                    result.accessToken = tempToken.accessToken;
                }
                let response = null;
                setError(null);
                const headers = new Headers();
                const bearer = `Bearer ${result.accessToken}`;
                headers.append("Authorization", bearer);

                if (data) headers.append('Content-Type', 'application/json');

                let options = {
                    method: method,
                    headers: headers,
                    body: data ? JSON.stringify(data) : null,
                };
                setIsLoading(true);
                if (isBlob) {
                    response = await (await fetch(endpoint, options));
                        if (!response.ok) 
                            throw new Error(response.statusText)
                        response = response.blob();
                }else {  // JSON                    
                    response = await (await fetch(endpoint, options));
                    if (response.status === 200)
                        response = response.json();
                    if (!(response instanceof Promise) && !response.ok)
                        throw new Error(response.statusText);                    
                }
                setData(response);

                setIsLoading(false);
                
                return response;
            } catch (e) {
                setError(e);
                setIsLoading(false);
                throw e;
            }
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect`
    };
};

export default useFetchWithMsal;