import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
import AuthGuard from 'src/guards/authGuard/AuthGuard';
import GuestGuard from 'src/guards/authGuard/GuestGaurd';
import {InteractionType} from "@azure/msal-browser";
import {MsalAuthenticationTemplate} from "@azure/msal-react";
import {loginRequest} from "../authConfig";
import AddEditOrder from "../views/order-management/dialogs/order";


/* ****Pages***** */
const HomePage = Loadable(lazy(() => import('../views/homepage/HomePage')));
const TechPage = Loadable(lazy(() => import('../views/tech/TechPage')));
const Geotool = Loadable(lazy(() => import('../views/tech/Geotool')));
const Scheduled = Loadable(lazy(() => import('../views/order-management/Scheduled')));
const Active = Loadable(lazy(() => import('../views/order-management/Active')));
const Closed = Loadable(lazy(() => import('../views/order-management/Closed')));
const Manage = Loadable(lazy(() => import('../views/order-management/Manage')));
const Edit = Loadable(lazy(() => import('../views/order-management/dialogs/order')));
const View = Loadable(lazy(() => import('../views/order-management/View')));
const Control = Loadable(lazy(() => import('../views/order-management/Control')));
const HistorySale = Loadable(lazy(() => import('../views/order-management/HistorySale')));
const HistoryTraffic = Loadable(lazy(() => import('../views/order-management/HistoryTraffic')));
const Advertiser = Loadable(lazy(() => import('../views/user-management/Advertiser')));
const AdvertiserReferral = Loadable(lazy(() => import('../views/user-management/AdvertiserReferral')));
const Agency = Loadable(lazy(() => import('../views/user-management/Agency')));
const Admin = Loadable(lazy(() => import('../views/user-management/Admin')));
const Referral = Loadable(lazy(() => import('../views/user-management/Referral')));
const Sales = Loadable(lazy(() => import('../views/user-management/Sales')));
const Traffic = Loadable(lazy(() => import('../views/user-management/Traffic')));
const AudienceInsights = Loadable(lazy(() => import('../views/report/AudienceInsights')));
const CampaignReport = Loadable(lazy(() => import('../views/report/CampaignReport')));
const CampaignReportPaginated = Loadable(lazy(() => import('../views/report/CampaignReportPaginated')));
const ReportList = Loadable(lazy(() => import('../views/report/ReportList')));
const AdministationReport = Loadable(lazy(() => import('../views/report/AdministrationReport')));
const AigenReport = Loadable(lazy(() => import('../views/report/AigenReport')));
const FileManagement = Loadable(lazy(() => import('../views/sales/FileManagement')));
const PrecampaignList = Loadable(lazy(() => import('../views/precampaign/PrecampaignList')));
const PrecampaignManage = Loadable(lazy(() => import('../views/precampaign/PrecampaignManage')));
const PrecampaignEdit = Loadable(lazy(() => import('../views/precampaign/PrecampaignEdit')));
const PrecampaignReport = Loadable(lazy(() => import('../views/precampaign/PrecampaignReport')));
const PrecampaignCategory = Loadable(lazy(() => import('../views/precampaign/ManageCategory')));
const RetailMediaInsert = Loadable(lazy(() => import('../views/media/RetailMediaInsert')));
const RetailStep2 = Loadable(lazy(() => import('../views/media/RetailStep2')));
const RetailStep3 = Loadable(lazy(() => import('../views/media/RetailStep3')));
const RetailStep4 = Loadable(lazy(() => import('../views/media/RetailStep4')));
const RetailStep5 = Loadable(lazy(() => import('../views/media/RetailStep5')));
const TargetFinderInsertStep1 = Loadable(lazy(() => import('../views/target-finder/TargetFinderInsertStep1')));
const TargetFinderInsertStep2 = Loadable(lazy(() => import('../views/target-finder/TargetFinderInsertStep2')));
const TargetFinderInsertStep3 = Loadable(lazy(() => import('../views/target-finder/TargetFinderInsertStep3')));
const TargetFinderInsertStep4 = Loadable(lazy(() => import('../views/target-finder/TargetFinderInsertStep4')));
const TargetFinderPrint= Loadable(lazy(() => import('../views/target-finder/TargetFinderPrint')));
const TargetFinderList = Loadable(lazy(() => import('../views/target-finder/TargetFinderList')));


// authentication

const authRequest = {
  ...loginRequest,
};
const Router = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
        <FullLayout />
        </MsalAuthenticationTemplate>
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Navigate to="/home" /> },
      { path: '/login', element: <Navigate to="/home" /> },
      { path: '/home', exact: true, element: <HomePage /> },
      { path: '/tech', exact: true, element: <TechPage /> },
      { path: '/geotool', exact: true, element: <Geotool /> },
      { path: '/order/scheduled', exact: true, element: <Scheduled />  },
      { path: '/order/active', exact: true, element: <Active />  },
      { path: '/order/closed', exact: true, element: <Closed />  },
      { path: '/order/manage', exact: true, element: <Manage />  },
      { path: '/order/edit', exact: true, element: <AddEditOrder />  },
      { path: '/order/view', exact: true, element: <View />  },
      { path: '/order/control', exact: true, element: <Control />  },
      { path: '/order/historySale', exact: true, element: <HistorySale />  },
      { path: '/order/historyTraffic', exact: true, element: <HistoryTraffic />  },
      { path: '/user/admin', exact: true, element: <Admin />  },
      { path: '/user/advertiser', exact: true, element: <Advertiser />  },
      { path: '/user/advertiserReferral', exact: true, element: <AdvertiserReferral />  },
      { path: '/user/agency', exact: true, element: <Agency />  },
      { path: '/user/referral', exact: true, element: <Referral />  },
      { path: '/user/sales', exact: true, element: <Sales />  },
      { path: '/user/traffic', exact: true, element: <Traffic />  },
      { path: '/mobility', exact: true, element: <AudienceInsights />  },
      { path: '/report/campaign', exact: true, element: <CampaignReport />  },
      { path: '/report/view', exact: true, element: <CampaignReportPaginated />  },
      { path: '/reportList', exact: true, element: <ReportList /> },
      { path: '/fileManagement', exact: true, element: <FileManagement /> },
      { path: '/precampaign/manage', exact: true, element: <PrecampaignManage /> },
      { path: '/precampaign/edit', exact: true, element: <PrecampaignEdit /> },
      { path: '/precampaign/list', exact: true, element: <PrecampaignList /> },
      { path: '/precampaign/report', exact: true, element: <PrecampaignReport /> },
      { path: '/precampaign/category', exact: true, element: <PrecampaignCategory /> },
      { path: '/retailmedia/add', exact: true, element: <RetailMediaInsert /> },
      { path: '/retailmedia/step2', exact: true, element: <RetailStep2 /> },
      { path: '/retailmedia/step3', exact: true, element: <RetailStep3 /> },
      { path: '/retailmedia/step4', exact: true, element: <RetailStep4 /> },
      { path: '/retailmedia/step4', exact: true, element: <RetailStep4 /> },
      { path: '/retailmedia/step5', exact: true, element: <RetailStep5 /> },
      { path: '/reportAigen', exact: true, element: <AigenReport /> },
      { path: '/ReportAdministration', exact: true, element: <AdministationReport /> },
      { path: '/target-finder/new', exact: true, element: <TargetFinderInsertStep1 /> },
      { path: '/target-finder/step2', exact: true, element: <TargetFinderInsertStep2 /> },
      { path: '/target-finder/step3', exact: true, element: <TargetFinderInsertStep3 /> },
      { path: '/target-finder/step4', exact: true, element: <TargetFinderInsertStep4 /> },
      { path: '/target-finder/list', exact: true, element: <TargetFinderList /> },
      { path: '/target-finder/print', exact: true, element: <TargetFinderPrint /> },


      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: (

        <BlankLayout />

    ),
    children: [
   /*   { path: '/auth/login', element: <Login /> },
      { path: '/auth/login2', element: <Login2 /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/register2', element: <Register2 /> },*/
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
     // { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
